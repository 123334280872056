import React from "react";

const Loading = () =>{
    return(
        <>
           <div className='text-center mx-auto display-1 py-5 my-5'>Loading...</div>

        </>

    );
}

export default Loading;