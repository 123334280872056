import React from "react";
import PageHeader from "../../components/PageHeader";
import EmailIcon from '@mui/icons-material/Email';
import ContactForm from "./ContactForm";
// import HubSpotForm from "./HubSpotForm";
const Contact = () =>{
    return(
        <>
            <PageHeader title="CONTACT" description="Shoot us a message with your inquiries and we connect with you." />
            <div className="container-full-width border-top py-5">
                <div className="row">
                    <div className="col-md-6 mb-5">
                        <p style={{'fontWeight':'300'}} className="display-6">LET'S WORK TOGETHER.</p>
                        <p style={{'fontWeight':'300', 'fontSize':'24px'}} >We would love to discuss your next dream project with you.</p>
                        <p className="my-0"><a  style={{'fontSize': '30px'}}  className="text-decoration-none text-dark" href="mailto:info@toptechgiants.com"><EmailIcon  className="me-2"  style={{'fontSize': '40px'}} />info@toptechgiants.com</a></p>

                    </div>
                    <div className="col-md-6">
                       
                        <ContactForm />
                    </div>
                    

                </div>
             
            </div>
        </>

    );
}

export default Contact;