import React, { useState, useEffect } from 'react';
import contentfulConfig from '../../config/contentfulConfig';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import Loading from "../../components/Loading";
import Marquee from "react-fast-marquee";


const client = createClient({
  space: contentfulConfig.space,
  accessToken: contentfulConfig.accessToken,
});

function Clients() {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Fetch data from Contentful
    client
      .getEntries({
        content_type: 'clients',
        // You can add additional filters or options here
      })
      .then((response) => {
        setEntries(response.items);
        setIsLoading(false); // Set loading state to false when data is fetched
      })
      .catch(console.error);
  }, []);


  const totalEntries = entries.length;
  const marquee1Entries = entries.slice(0, Math.ceil(totalEntries / 2));
  const marquee2Entries = entries.slice(Math.ceil(totalEntries / 2));


  return (
    <>
    
      {isLoading ? ( // Check loading state
        <Loading />
      ) : (
          <>
         

         <Marquee direction="right" speed="50">
          {marquee1Entries.map((entry, index) => (
            <a key={index} href="#" className='me-5'>
              <img width={200} className='client-logo' src={entry.fields.icon.fields.file.url} alt={entry.fields.title} />
            </a>
          ))}
        </Marquee>
        <Marquee direction="left" speed="60">
          {marquee2Entries.map((entry, index) => (
              <img key={index} width={200}  className='client-logo' src={entry.fields.icon.fields.file.url} alt={entry.fields.title} />
              ))}
        </Marquee>
       
          
          </>
      )}
    </>
  );
}

export default Clients;
