import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import Image_Scott from './images/scott.jpg';
import Image_Ozy from './images/ozy.png';
import Image_Frank from './images/frank.jpeg';


export default function App() {
  return (
    <MDBContainer className="py-5">

      <MDBRow className="text-center d-flex align-items-stretch">
        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
          <MDBCard className="testimonial-card">
            <div
              className="card-up"
              style={{ backgroundColor: "#9d789b" }}
            ></div>
            <div className="avatar mx-auto bg-white">
              <img
                src={Image_Scott}
                className="rounded-circle img-fluid"
              />
            </div>
            <MDBCardBody>
              <h4 className="mb-2">SCOTT J. BELSLEY, MD, FACS</h4>
              <p>Director of Mt. Sinai West Division of General Surgery. He is an experienced, board-certified, minimally invasive surgeon who has worked at the same hospital for almost twenty year</p>
              <hr />
              <p className="dark-grey-text mt-4">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                One of the best and most professional programmers I have worked with in a long time. His coding is efficient and avoids redundancies. Sudarshan completed the project with a perfect combination of availability and independence. Will continue to use on future projects.
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
          <MDBCard className="testimonial-card">
            <div
              className="card-up"
              style={{ backgroundColor: "#7a81a8" }}
            ></div>
            <div className="avatar mx-auto bg-white">
            <img
                src={Image_Ozy}
                className="rounded-circle img-fluid"
              />
            </div>
            <MDBCardBody>
              <h4 className="mb-2">Anthony (Ozy) Abram</h4>
              <p>Chief Editor at Uni-edit(uni-edit.net) </p>
              <hr />
              <p className="dark-grey-text mt-4">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Sudarshan is a pleasure to work with and the new website is functioning very smoothly.
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
          <MDBCard className="testimonial-card">
            <div
              className="card-up"
              style={{ backgroundColor: "#6d5b98" }}
            ></div>
            <div className="avatar mx-auto bg-white">
            <img
                src={Image_Frank}
                className="rounded-circle img-fluid"
              />
            </div>

            <MDBCardBody>

              <h4 className="mb-2">Frank Debenedetti</h4>
              <p>owner and operator of GolfTraxx.com</p>
              <hr />
              <p className="dark-grey-text mt-4">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Sudarshan has been working on several milestones of our mobile deployment project for golftraxx.com over the past few months and has been an outstanding contributor. He is persistent on researching and resolving any issues as they arise and we are very pleased with his performance. His willingness to go beyond the basic requirement to deliver excellent results has earned him more than a few bonuses. We hope to do many more projects with him.
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        
      </MDBRow>
    </MDBContainer>
  );
}