import React, { useState, useEffect } from "react";
import ProjectData from "./ProjectData";


const currentURL = window.location.href;

// Use regular expression to extract the value
const regex = /projects\/([^/]+)/;
const match = currentURL.match(regex);

// Check if a match was found and get the value
let projectId = null;
if (match) {
   projectId = match[1];
   console.log(projectId)
} else {
  console.log("Value not found");
}
const ProjectDetail = props => {

  return (
    <div>
        <ProjectData projectId={projectId} />
         
    </div>
  );
};

export default ProjectDetail;
