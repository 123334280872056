import React from "react";

const PageHeader = (props) => {
  return (
    <>
      <div className="section page-header">
        <div className="container-full-width">
          <div className="title-desc-wrapper">
            <h1 className="page-title display-2">{props.title}.</h1>
            <div className="description ">
              {props.description}
            </div>

          </div>
          
        </div>
      </div>
    </>
  );
};

export default PageHeader;
