import React from "react";
import PageHeader from "../../components/PageHeader";
const About = () =>{
    return(
        <>
            <PageHeader title="ABOUT" description="We are a Software Development Company. We help businesses, start-ups and nonprofits conceive and create brands, websites, tools, and apps that customers love." />
            <div className="container-full-width border-top py-5">
                <div className="row">
                    <div className="col-md-8">
                    <div><h3>Who We Are</h3>
                    
                    <p>We are a distinguished company in the field of web applications, recognized for our expertise in assisting federal agencies, non-profits, and associations with their web and mobile technology requirements. Our focus is on enhancing the speed, simplicity, and user-friendliness of their websites. Leveraging our extensive experience and successful track record in developing web and mobile applications, we specialize in delivering tailored, high-quality solutions within the specified timeframe and budget. Building enduring relationships with our clients is a priority for us. At Top Tech Giants, we not only listen to our clients but also internalize their preferences, ensuring that we deliver solutions that not only meet but exceed their expectations. The outcome is a high level of satisfaction among our clients.</p>
                    
                    <h3>Why We Are Different</h3>
                    <p>Every day, we dedicate ourselves to attaining the utmost quality and cost-effective solutions. Through rigorous testing and effective communication, we ensure the delivery of functional software. Our comprehensive process not only saves our clients time and money but also enhances workflows, business intelligence, and brand awareness. Employing software development and web best practices, we model our web designs and applications on industry standards. The outcomes we provide include:</p><ul><li>Meticulousness is our focus – we conduct thorough testing, fine-tune, and ensure your website operates at peak performance levels.</li>
                    <li>Embracing innovation - we allocate a portion of our time to explore emerging technologies and their potential benefits for our clients.</li>
                    <li>We attentively heed our clients, comprehend their needs, and provide precisely what they desire.</li></ul></div>

                    </div>
                </div>
             
            </div>
        </>

    );
}

export default About;