import React, { useState } from 'react';

export default function BasicForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(''); // New subject field
  const [message, setMessage] = useState('');

  const [error, setError] = useState('');

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    // Validation: Ensure all fields are filled
    if (!name || !email || !subject || !message) {
      setError('All fields are required.');
      return;
    }

    setError(''); // Clear error if validation passes

    fetch("https://formcarry.com/s/4X3wVH-9nR3", {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name, email, subject, message }) // Include subject
    })
    .then(response => response.json())
    .then(response => {
      if (response.code === 200) {
        alert("We received your submission, thank you!");
      } else if (response.code === 422) {
        // Field validation failed
        setError(response.message);
      } else {
        // other error from formcarry
        setError(response.message);
      }
    })
    .catch(error => {
      // request-related error
      setError(error.message ? error.message : error);
    });
  }

  return (
    <form className='contact-form' onSubmit={onSubmit}>
      {error && <p className="error">{error}</p>} {/* Show error message */}

      <div className="formcarry-block">
        <label htmlFor="name">Full Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="name"
          placeholder="Your first and last name"
          required
        />
      </div>

      <div className="formcarry-block">
        <label htmlFor="email">Your Email Address</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          placeholder="john@doe.com"
          required
        />
      </div>

      <div className="formcarry-block">
        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          id="subject"
          placeholder="Enter the subject"
          required
        />
      </div>

      <div className="formcarry-block">
        <label htmlFor="message">Your Message</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          id="message"
          placeholder="Enter your message..."
          required
          rows={5}
        ></textarea>
      </div>

      <div className="formcarry-block">
        <button className='btn-outline-dark' type="submit">Send</button>
      </div>
    </form>
  );
}
