import React from "react";
import PageHeader from "../../components/PageHeader";
import Button from '@mui/material/Button';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
const Resume = () =>{
    const onDownload = () => {
        const link = document.createElement("a");
        link.href = "https://drive.google.com/uc?export=download&id=1vp9WEFO8fKBKNqUGcNqCRXiuZnL8PjIg";
        link.click();
      };
      
    return(
        <>
            <PageHeader 
            title="Resume" 
            description="Developer Resume Download" 
         
            />
            <Button color="primary" className="text-center mb-5" onClick={onDownload} component="label" 
            variant="outlined"
            style={{'fontSize':'20px','width': 'fit-content', 'margin':'auto', 'display': 'flex'}}  
            startIcon={<DownloadForOfflineIcon style={{'fontSize':'100px'}} />}>
                
            Download Resume
            </Button>
            <div className="container">
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: 0,
                    paddingTop: '141.4286%',
                    paddingBottom: 0,
                    boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
                    marginTop: '1.6em',
                    marginBottom: '0.9em',
                    overflow: 'hidden',
                    borderRadius: '8px',
                    willChange: 'transform',
                    border: '1px solid #000'
                }}
                >
                <iframe
                    loading="lazy"
                    style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    }}
                    src="https://www.canva.com/design/DAFOAHVxKCk/view?embed"
                    allowFullScreen
                    allow="fullscreen"
                    title="Embedded Canva Design"
                ></iframe>
                </div>
                <a
                href="https://www.canva.com/design/DAFOAHVxKCk/view?utm_content=DAFOAHVxKCk&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
                target="_blank"
                rel="noopener"
                >
                Resume by sudarshan giri
                </a>
           
            </div>
            

        </>

    );
}

export default Resume;